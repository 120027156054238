<template>
  <div id="manage-product-list" class="fx-main container">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    
    <div class="wd-100 g-t-c-1 fx fx-wrap main-product-flex" ref="plistRef">
      <div
        v-for="product in productList"
        :key="product.id"
        class="manage-product-card fx-main manage-product-item txt-align-center default-border fx-a-i-cent shadow pd-2-em main-grid j-i p fix-column grid-fix-manage"
      >
        <div class="manage-product-img shadow mg-right margin-img fx-center">
          <img
            class="fx-center"
            v-if="product.imageUrl && product.imageUrl[0]"
            :src="product.imageUrl[0]"
          />
        </div>
        <div class="manage-product-name width-80 name-bold margin-top">
          <router-link
            :to="{ name: 'p-detail', params: { product_id: product.id }, query: { supplier_id: product.supplier_id } }"
          >
            <span class="product-name">{{ product.name }}</span>
          </router-link>
        </div>
        <div class='shadow produdct-stock pd-1-em' v-bind:class="{ red : product.stocked_quantity == 0}"> 
              {{ product.stocked_quantity }}
        </div>
        <div class="manage-product-price">
          <span class="product-price">{{ product.price | toCurrency }}</span>
        </div>
        <div  v-if="product.plotted_price" class="manage-product-plotted_price" >
            Plotar :
          <span class="product-plotted_price">{{ product.plotted_price | toCurrency }}</span>
        </div>
        <div class="edit-product">
          <!-- <i class="fas fa-ellipsis-h"></i> -->
          <div class="margin-10-px fx-center 1fx-wrap">
            <div
              v-on:click="showModal(product.id, product.stocked_quantity )"
              class="default-border style-btn margin-10-px btn"
            >
              <span>Editar</span>
            </div>
            
            <div
              class="icon-circle shadow clickable delete-product fx-center"
              v-on:click="deleteProduct(product.id)"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="product-manage-modal" :width="'85%'" :height="'80%'" :adaptive="true">
      <div class="container">
        <div class="modal-header fx-main fx-end pd-15-px">
          <i class="clickable fa fa-times" aria-hidden="true" @click="hideModal"></i>
        </div>
        <div class="fx-main">
          <div class="fx-main flex-md-fill">
            <div class="full-width">
              <div id="FormProduct">
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" for="external_id">Identificador Externo do Produto</label>
                  <input
                    class="default-border"
                    type="text"
                    name="external_id"
                    id="external_id"
                    v-model="productModal.external_id"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" for="name">Nome do Produto</label>
                  <input
                    class="default-border"
                    type="text"
                    name="name"
                    id="name"
                    v-model="productModal.name"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" for="short_description">Curta Descrição do Produto</label>
                  <input
                    class="default-border"
                    type="text"
                    name="short_description"
                    id="short_description"
                    v-model="productModal.short_description"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" for="prod_form_price">Preço</label>
                  <money
                    class="default-border"
                    type="text"
                    id="prod_form_price"
                    v-model="productModal.price"
                    v-bind="money"
                  ></money>
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" for="prod_form_plotted_price">Preço (Plotted)</label>
                  <money
                    class="default-border"
                    type="text"
                    id="prod_form_plotted_price"
                    v-model="productModal.plotted_price"
                    v-bind="money"
                  ></money>
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" for="stocked_quantity">Estoque</label>
                  <input
                    class="default-border"
                    type="number"
                    name="stocked_quantity"
                    id="stocked_quantity"
                    v-model="productModal.stocked_quantity"     
                  />
                  <!-- v-model="productModal.product_quantity" -> Alterado de .product_quantity para .stocked_quantity -->
                </div>
                <div class="input-group fx-main mg-bot-2em" v-if="productModal.variations"> 
                  <div  v-if="productModal.variations[0]">
                    <label class="mg-right-2-em" for="stocked_quantity1">Estoque das variações</label>
                      <div class="ml-3">
                        {{ productModal.stocked_quantity }}
                      </div>  
                  </div>
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" for="description">Descrição</label>
                  <textarea
                    class="default-border"
                    type="text"
                    name="description"
                    id="description"
                    v-model="productModal.description"
                  />
                </div>

                <div class="input-group fx-main mg-bot-2em" v-if="productModal.categories">
                  <label class="mg-right-2-em" for="prod_form_categories">Categorias</label>
                  <select
                    multiple="multiple"
                    class="border-radius-10 pd-0 scrollbar-none select-style"
                    id="prod_form_categories"
                    v-model="productModal.categories"
                  >
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >{{ category.name }}</option>
                  </select>
                </div>

                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" for="prod_form_images">Adicionar Imagens</label>
                  <input
                    class="default-border"
                    type="file"
                    multiple="multiple"
                    @change="processFile($event)"
                    ref="fileInput"
                    id="prod_form_images"
                    name="prod_form_images"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <button
                    id="btn_manage_images"
                    class="default-border clickable btn style-button"
                    @click="manageImages()"
                  >GERENCIAR IMAGENS</button>
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <button
                    id="btn_manage_files"
                    class="default-border clickable btn style-button"
                    @click="manageFiles()"
                  >GERENCIAR ARQUIVOS</button>
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <button
                    id="btn_manage_rules"
                    class="default-border clickable btn style-button"
                    @click="manageRules()"
                  >GERENCIAR REGRAS DE PREÇO</button>
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <button
                    id="btn_manage_rules"
                    class="default-border clickable btn style-button"
                    @click="manageVariations()"
                  >GERENCIAR VARIAÇÕES</button>
                </div>

                <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
                  <span class="mg-right-2-em" for="prod_form_atacado">Atacado</span>
                  <div class="switch">
                    <input
                      type="checkbox"
                      class="switch__input"
                      id="prod_form_atacado"
                      v-model="productModal.price_rules_enabled"
                      :true-value="true"
                      :false-value="false"
                    />
                    <label for="prod_form_atacado" class="switch__label"></label>
                  </div>
                </div>
                <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
                  <span class="mg-right-2-em" for="prod_form_varejo">Varejo</span>
                  <div class="switch">
                    <input
                      type="checkbox"
                      class="switch__input"
                      id="prod_form_varejo"
                      v-model="productModal.root_price_enabled"
                      :true-value="true"
                      :false-value="false"
                    />
                    <label for="prod_form_varejo" class="switch__label"></label>
                  </div>
                </div>
                <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
                  <span class="mg-right-2-em" for="prod_form_published">Visível</span>
                  <div class="switch">
                    <input
                      type="checkbox"
                      class="switch__input"
                      id="prod_form_published"
                      v-model="productModal.published"
                      :true-value="true"
                      :false-value="false"
                    />
                    <label for="prod_form_published" class="switch__label"></label>
                  </div>
                </div>
                <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
                  <span class="mg-right-2-em" for="prod_form_published">Comprável</span>
                  <div class="switch">
                    <input
                      type="checkbox"
                      class="switch__input"
                      id="prod_form_sellable"
                      v-model="productModal.sellable"
                      :true-value="true"
                      :false-value="false"
                    />
                    <label for="prod_form_sellable" class="switch__label"></label>
                  </div>
                </div>
                <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
                  <span class="mg-right-2-em" for="prod_form_tangible">Tangível</span>
                  <div class="switch">
                    <input
                      type="checkbox"
                      class="switch__input"
                      id="prod_form_tangible"
                      v-model="productModal.tangible"
                      :true-value="true"
                      :false-value="false"
                    />
                    <label for="prod_form_tangible" class="switch__label"></label>
                  </div>
                </div>

                <template v-if="productModal.tangible && productModal.box">
                  <div class="input-group fx-main mg-bot-2em">
                    <label class="mg-right-2-em" for="prod_form_weight">Peso (g)</label>
                    <input
                      class="default-border"
                      type="number"
                      id="prod_form_weight"
                      v-model="productModal.box.weight"
                    />
                  </div>
                  <div class="input-group fx-main mg-bot-2em">
                    <label class="mg-right-2-em" for="prod_form_height">Altura (cm)</label>
                    <input
                      class="default-border"
                      type="number"
                      id="prod_form_height"
                      v-model="productModal.box.height"
                    />
                  </div>
                  <div class="input-group fx-main mg-bot-2em">
                    <label class="mg-right-2-em" for="prod_form_width">Largura (cm)</label>
                    <input
                      class="default-border"
                      type="number"
                      id="prod_form_width"
                      v-model="productModal.box.width"
                    />
                  </div>
                  <div class="input-group fx-main mg-bot-2em">
                    <label class="mg-right-2-em" for="prod_form_length">Comprimento (cm)</label>
                    <input
                      class="default-border"
                      type="number"
                      id="prod_form_length"
                      v-model="productModal.box.length"
                    />
                  </div>
                </template>

                <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
                  <span class="mg-right-2-em" for="prod_form_recurrent">Recorrente</span>
                  <div class="switch">
                    <input
                      type="checkbox"
                      class="switch__input"
                      id="prod_form_recurrent"
                      v-model="productModal.recurrent"
                      :true-value="true"
                      :false-value="false"
                    />
                    <label for="prod_form_recurrent" class="switch__label"></label>
                  </div>
                </div>

                <template v-if="productModal.recurrent && productModal.recurrence_info">
                  <div class="input-group fx-main mg-bot-2em">
                    <label class="mg-right-2-em" for="prod_form_weight">Tipo de Intervalo</label>
                    <select
                      id="prod_form_weight"
                      v-model="productModal.recurrence_info.payment_interval"
                      class="default-border wd-100"
                    >
                      <option
                        class="option-style"
                        v-for="interval_type in intervals"
                        :key="interval_type.value"
                        :value="interval_type.value"
                      >{{ interval_type.name }}</option>
                    </select>
                  </div>
                  <div class="input-group fx-main mg-bot-2em">
                    <label class="mg-right-2-em" for="prod_form_payment_number">Intervalo Numérico</label>
                    <input
                      class="default-border"
                      type="number"
                      id="prod_form_payment_number"
                      v-model="productModal.recurrence_info.payment_number"
                    />
                  </div>
                </template>
                <div class="input-group fx-main mg-bot-2em">
                  <button
                    class="btn clickable"
                    v-on:click="updateProduct(productModal.id)"
                  >Alterar Produto</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="image-manage-modal"
      ref="imageManageModal"
      :style="variationIndex !== null ? 'z-index:9999' : ''"
      :adaptive="true"
      :width="'70%'"
      :height="'80%'"
      v-if="productModal && productModal.imageUrl"
    >
      <div class="pd-15-px modal-header fx-main fx-end">
        <i class="fa fa-times" aria-hidden="true" @click="hideImageModal"></i>
      </div>
      <div class="pd-15-px fx-main flex-md-fill">
        <div class="full-width flex-md-fill">
          <div id="FormImages">
            <div class="input-group fx-main mg-bot-2em fx-start">
              <div
                class="default-border fx-center col-md-2 mg-right-2em manage-img fx-wrap mg-top-2"
                v-for="(imageUrl, index) in (variationIndex !== null ? productModal.variations[variationIndex].imageUrl : productModal.imageUrl)"
                :key="imageUrl"
              >
                <img :src="imageUrl" :alt="'image-' + String(index)" />
                <div
                  class="label label-table label-danger clickable mg-top-2 left-img"
                  @click="leftImage(index)"
                >
                  <i class="fas fa-angle-left"></i>
                </div>
                
                <div
                  class="label label-table label-danger clickable mg-top-2 del-img circle shadow"
                  @click="eraseImage(variationIndex !== null ? productModal.variations[variationIndex].images[index] : productModal.images[index])"
                >
                  <i class="fas fa-trash clickable"></i>
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 right-img"
                  @click="rightImage(index)"
                >
                  <i class="fas fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="file-manage-modal"
      ref="fileManageModal"
      :style="variationIndex !== null ? 'z-index:9999' : ''"
      :adaptive="true"
      :width="'70%'"
      :height="'80%'"
      v-if="productModal"
    >
      <div class="modal-header fx-main fx-end pd-15-px">
        <i class="fa fa-times" aria-hidden="true" @click="hideFileModal"></i>
      </div>
      <div class="pd-15-px fx-main flex-md-fill">
        <div class="full-width flex-md-fill">
          <div id="FormFiles">
            <div class="input-group fx-main mg-bot-2em fx-start">
              <div>
                <span class="mg-right-2-em">Nome:</span>
                <input type="text" class="default-border wd-100" v-model="relatedFileCad.name" />
                <span class="mg-right-2-em">Descrição:</span>
                <input
                  type="text"
                  class="default-border wd-100"
                  v-model="relatedFileCad.description"
                />
                <span class="mg-right-2-em">Visível?</span>
                <input
                  type="checkbox"
                  v-model="relatedFileCad.published"
                  :true-value="true"
                  :false-value="false"
                />
                <span class="mg-right-2-em">Baixável?</span>
                <input
                  type="checkbox"
                  v-model="relatedFileCad.downloadable"
                  :true-value="true"
                  :false-value="false"
                />
                <span class="mg-right-2-em">Disponível Após Compra</span>
                <input
                  type="checkbox"
                  v-model="relatedFileCad.buyable"
                  :true-value="true"
                  :false-value="false"
                />
                <span class="mg-right-2-em">Selecionar Arquivo</span>
                <input
                  class="default-border"
                  type="file"
                  @change="variationIndex == null ? processRelatedFile($event) : processVariationRelatedFile($event, variationIndex)"
                  :ref="'relatedFileInput_o_' + (variationIndex == null ? 'RUT' : variationIndex)"
                />
                <div
                  class="clickable mg-top-2 btn"
                  @click="addFiles(productModal.id, variationIndex)"
                >
                  Adicionar Arquivo
                  <i class="fas fa-plus"></i>
                </div>
              </div>
              <div
                class="default-border fx-center col-md-2 mg-right-2em manage-img fx-wrap mg-top-2"
                v-for="(relatedFile, index) in (variationIndex !== null ? productModal.variations[variationIndex].related_files : productModal.related_files)"
                :key="relatedFile.id"
              >
                <p>{{ relatedFile.name }}</p>
                <p
                  v-if="relatedFile.description && relatedFile.description.length"
                >{{ relatedFile.description }}</p>
                <p v-if="relatedFile.is_loading">
                  <strong>Baixando...</strong>
                </p>
                <p v-if="relatedFile.blob_url">
                  <strong>Baixado! Clique novamente para salvar.</strong>
                </p>
                <a
                  :download="relatedFile.blob_url ? relatedFile.name : false"
                  @click="popBlobIfNot(productModal.id, variationIndex ? productModal.variations[variationIndex].id : null, relatedFile)"
                  :href="relatedFile.blob_url || 'javascript:void(0)'"
                >
                  <template v-if="relatedFile.blob_url">Salvar</template>
                  <template v-else>Baixar</template> Arquivo
                </a>
                <div
                  class="label label-table label-danger clickable mg-top-2 left-img"
                  @click="leftFile(index)"
                >
                  <i class="fas fa-angle-left"></i>
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 right-img"
                  @click="rightFile(index)"
                >
                  <i class="fas fa-angle-right"></i>
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 del-img"
                  @click="eraseFile(variationIndex !== null ? productModal.variations[variationIndex].related_files[index] : productModal.related_files[index])"
                >
                  <i class="fas fa-trash clickable"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="rules-manage-modal"
      :adaptive="true"
      :width="'70%'"
      :height="'80%'"
      v-if="productModal"
    >
      <div class="pd-15-px modal-header fx-main fx-end">
        <i class="fa fa-times" aria-hidden="true" @click="hideRulesModal"></i>
      </div>
      <div class="pd-15-px fx-main flex-md-fill">
        <div class="full-width flex-md-fill">
          <div id="FormRules fx-a-i-start">
            <div class="clickable mg-top-2 btn" @click="addRule()">
              Adicionar Regra
              <i class="fas fa-plus"></i>
            </div>
            <div class="input-group fx-main mg-bot-2em mg-top-2 fx-start">
              <div
                class="default-border fx-main col-md-2 mg-right-2em fx-wrap"
                v-for="(rule, index) in productModal.price_rules"
                :key="index"
              >
                <span class="mg-right-2-em">Quantidade Mínima:</span>
                <input type="number" class="default-border wd-100" v-model="rule.min_quantity" />
                <span class="mg-right-2-em">Quantidade Máxima:</span>
                <input type="number" class="default-border wd-100" v-model="rule.max_quantity" />
                <span class="mg-right-2-em">Valor:</span>
                <money
                  v-if="rule.modifier_type === 'PRICE'"
                  class="default-border wd-100"
                  type="text"
                  v-model="rule.amount"
                  v-bind="money"
                ></money>
                <the-mask
                  v-if="rule.modifier_type === 'PERCENTAGE'"
                  v-model="rule.amount"
                  mask="##%"
                  type="text"
                  :masked="false"
                  class="default-border wd-100"
                ></the-mask>
                <span class="mg-right-2-em wd-100">Tipo de Valor:</span>
                <div class="fx-main wd-100">
                  <label>Porcentagem</label>
                  <input
                    type="radio"
                    :name="'rule_mod_' + index"
                    v-model="rule.modifier_type"
                    value="PERCENTAGE"
                  />
                </div>
                <div class="fx-main wd-100">
                  <label>Preço</label>
                  <input
                    type="radio"
                    :name="'rule_mod_' + index"
                    v-model="rule.modifier_type"
                    value="PRICE"
                  />
                </div>
                <div class="fx-main wd-100">
                  <label>Habilitada</label>
                  <input
                    type="checkbox"
                    v-model="rule.enabled"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
                <div class="fx-main wd-100">
                  <label>Relativa</label>
                  <input
                    type="checkbox"
                    v-model="rule.relative"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 del-img wd-100"
                  @click="eraseRule(index)"
                >
                  <i class="fas fa-trash clickable wd-100"></i>
                </div>
              </div>
              <div class="btn clickable mg-top-2" @click="saveRules()">
                Salvar
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="variations-manage-modal"
      :adaptive="true"
      :width="'70%'"
      :height="'80%'"
      v-if="productModal"
    >
      <div class="pd-15-px modal-header fx-main fx-end">
        <i class="fa fa-times" aria-hidden="true" @click="hideVariationsModal"></i>
      </div>
      <div class="pd-15-px fx-main flex-md-fill">
        <div class="full-width flex-md-fill">
          <div id="FormRules fx-a-i-start">
            <div class="clickable mg-top-2 btn" @click="addVariation()">
              Adicionar Variação
              <i class="fas fa-plus"></i>
            </div>
            <div class="input-group fx-main mg-bot-2em mg-top-2 fx-start">
              <div
                class="default-border-MProduct fx-main col-md-2 mg-right-2em fx-wrap"
                v-for="(variation, index) in productModal.variations"
                :key="index"
              >
                <div class="input-group fx-main mg-bot-2em">
                  <label
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_external_id'"
                  >Identificador Externo da Variação</label>
                  <input
                    class="default-border"
                    type="text"
                    :name="'prod-var-' + index + '_external_id'"
                    :id="'prod-var-' + index + '_external_id'"
                    v-model="variation.external_id"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" :for="'prod-var-' + index + '_name'">Nome da Variação</label>
                  <input
                    class="default-border"
                    type="text"
                    :id="'prod-var-' + index + '_name'"
                    v-model="variation.name"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" :for="'prod-var-' + index + '_form_price'">Preço</label>
                  <money
                    class="default-border wd-100"
                    :id="'prod-var-' + index + '_form_price'"
                    type="text"
                    v-model="variation.price"
                    v-bind="money"
                    :enabled="!variation.extends_price"
                  ></money>
                </div>
                <div class="input-group fx-main mg-bot-2em fx-start fx-a-i-cent">
                  <span class="mg-right-2-em" :for="'prod-var-' + index + '_form_exp'">Herdar Preço</span>
                  <input
                    type="checkbox"
                    :id="'prod-var-' + index + '_form_exp'"
                    v-model="variation.extends_price"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_stocked_quantity'"
                  >Estoque</label>
                  <input
                    class="default-border"
                    type="number"
                    :id="'prod-var-' + index + '_stocked_quantity'"
                    v-model="variation.stocked_quantity"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label class="mg-right-2-em" :for="'prod-var-' + index + '_description'">Descrição</label>
                  <textarea
                    class="default-border"
                    type="text"
                    :id="'prod-var-' + index + '_description'"
                    v-model="variation.description"
                  />
                </div>
                <!-- <div class="input-group fx-main mg-bot-2em" v-if="variation.categories">
                  <label class="mg-right-2-em" for="prod_form_categories">Categorias</label>
                  <select
                    multiple="multiple"
                    class="default-border"
                    id="prod_form_categories"
                    v-model="variation.categories"
                  >
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >{{ category.name }}</option>
                  </select>
                </div>-->
                <div class="input-group fx-main mg-bot-2em" v-if="variation.id">
                  <label class="mg-right-2-em" for="name">Adicionar Imagens</label>
                  <input
                    class="default-border"
                    type="file"
                    multiple="multiple"
                    @change="processVariationFile($event, index)"
                    :ref="'fileInput_var_' + index"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em" v-if="variation.id">
                  <button
                    id="btn_manage_images_variation"
                    class="default-border"
                    @click="manageImages(index)"
                  >Gerenciar Imagens</button>
                </div>
                <div class="input-group fx-main mg-bot-2em" v-if="variation.id">
                  <button
                    id="btn_manage_files_variation"
                    class="default-border"
                    @click="manageFiles(index)"
                  >Gerenciar Arquivos</button>
                </div>
                <div class="input-group fx-main mg-bot-2em fx-start fx-a-i-cent">
                  <span
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_form_exp'"
                  >Herdar Imagens</span>
                  <input
                    type="checkbox"
                    :id="'prod-var-' + index + '_form_exp'"
                    v-model="variation.extends_images"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
                <div
                  class="icon-circle label label-table label-danger clickable mg-top-2 del-img wd-100 shadow fx-center circle"
                  @click="eraseVariation(index)"
                >
                  <i class="fas fa-trash clickable wd-100"></i>
                </div>
              </div>
              <div class="btn clickable mg-top-2" @click="saveVariations()">
                Salvar
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<style scoped>

.shadow.produdct-stock.pd-1-em.red {
  color: #c92323c4;
  box-shadow: 0 0 2px 0 #c92323c4, 0 4px 4px 0 #c92323c4;
  -webkit-box-shadow: 0 0 2px 0 #c92323c4, 0 4px 4px 0 #c92323c4;
  
}

.fa-trash {
  color: #c92323c4;
}

.mg-right {
  justify-items: center;
}

.margin-img {
  margin : 1em 0;
}

.margin-10-px {
  margin: 10px;
}

.j-i {
  justify-items: center;
}

.width-80 {
  width: 80%;
}

.name-bold {
  font-weight: 700 !important;
}

#FormProduct {
  width: 200%;
  padding: 15px;
}

.pd-0 {
  padding: 0 2px;
}
.border-radius-10 {
  border-radius: 10px;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0;
  visibility: hidden;
}

.select-style {
  height: 120px;
  font-weight: bold;
  font-size: 0.9em;
}

.select-style option {
  background-color: #eee !important;
  margin: 6px 0;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 3px;
}

.option-style {
  background-color: #eee !important;
  margin: 6px 0;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 3px;
}

.style-button {
  margin-top: 0;
  width: 30%;
  max-width: 50%;
  font-size: 0.9em;
}

.default-border-MProduct {
  padding: 2%;
  border-radius: 10px;
  margin: 1%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06),
    0 0 2px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1),
    0 2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06),
    0 0 2px rgba(0, 0, 0, 0.07);
  border: 0.1em solid #006c7d;
}

.fa-trash {
  color: #c92323c4;
}

.manage-product-img.shadow.mg-right.margin-img.fx-center {
  width: 6em;
  height: 6em;
}

.manage-product-img.circle.shadow.mg-right.margin-img img {
  max-width: 100%;
  max-height: 100%;
}

.fx-main.manage-product-item.txt-align-center.default-border.fx-a-i-cent.shadow.pd-2-em.main-grid.gd-tp-c.gd-tp-r.j-i.p
  img {
  max-width: 100%;
  max-height: 100%;
}

.fix-column { 
    display: grid !important;
    padding: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.main-product-flex .p {
    width: 100% !important;
}

.grid-fix-manage {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.label.label-table.label-danger.clickable.mg-top-2.left-img,
.label.label-table.label-danger.clickable.mg-top-2.right-img {
    padding: 1em;
    border: 1px solid black;
    border-radius: 3em;
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>

<script>
import Product from "@/services/product.js";
import { apiBaseUrl } from "@/services/api.js";
import HeaderBuilder from "@/services/header-builder.js";
import util from "@/services/util";
import { Money } from "v-money";
import axios from "axios";
import arrayMove from "array-move";
import { TheMask } from "vue-the-mask";
import Categories from "@/services/categories";
import Loading from "vue-loading-overlay";

export default {
  name: "manage-product-list",
  props: {
    product_id: String,
  },
  data: function () {
    return {
      binaryDataImg: [],
      variationFiles: [],
      binaryDataFile: [],
      variationRelatedFiles: [],
      variationIndex: null,
      productList: [],
      categories: [],
      intervals: [
        { value: "MONTHLY", name: "Mensalmente" },
        { value: "WEEKLY", name: "Semanalmente" },
        { value: "DAILY", name: "Diariamente" },
      ],
      searchParameters: {
        page: 0,
        perPage: 30,
        price_le: 0,
        price_ge: 0,
        ob_price: false,
        convert_prices: true,
      },
      loadingMore: false,
      loadIndex: 0,
      over: false,
      productModal: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      relatedFileCad: {
        name: "",
        description: "",
        published: true,
        downloadable: false,
        buyable: false,
      },
    };
  },
  mounted() {

    Categories.list().then((categories) => {
      this.categories = categories;
    });
    this.listProducts();

    

    // Add event listener for search parameter change (navbar search usually)
    this.$root.$on("searchParameters", this.onSearchParametersEvent);
    this.$root.$emit("openSearch");
    setTimeout(() => window.addEventListener("scroll", this.handleScroll), 500);
    if (this.product_id) {
      this.showModal(this.product_id);
    }

    
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    this.$root.$off("searchParameters", this.onSearchParametersEvent);
  },
  methods: {
    onSearchParametersEvent: function (searchParameters) {
      this.$root.$emit("searchListened");
      Object.assign(this.searchParameters, searchParameters);
      this.searchParameters.page = 0;
      this.loadingMore = false;
      this.over = false;
      this.listProducts();

    
    },
    popBlobIfNot: function (productId, variationId, related_file) {
      if (related_file.blob_url || related_file.is_loading) {
        return;
      }
      related_file.is_loading = true;
      this.$forceUpdate();
      const apiUrl =
        `${apiBaseUrl}/api/product/${productId}/related_file/${related_file.id}?apartment=${process.env.VUE_APP_TENANT_ID}` +
        (variationId ? `&variation_id=${variationId}` : "");
      axios
        .get(apiUrl, {
          responseType: "blob",
          headers: HeaderBuilder.build(),
        })
        .then(
          (response) => {
            related_file.blob_url = window.URL.createObjectURL(response.data);
            this.$forceUpdate();
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          related_file.is_loading = false;
          this.$forceUpdate();
        });
    },
    listProducts(push = false) {
      if (push) {
        if (this.loadingMore || this.over) {
          return;
        }
        this.searchParameters.page++;
        this.loadingMore = true;
      } else {
        this.searchParameters.page = 0;
        this.loadingMore = false;
        this.over = false;
        this.loadIndex++;
      }
      Product.list(this.searchParameters)
        .then((products) => {
          products.forEach((product) => {
            if (!product.categories) {
              product.categories = [];
            } else {
              product.categories = product.categories.map((c) => c.id);
            }
          });
          if (!push) {
            this.productList = products;
          } else {
            if (products.length === 0) {
              this.over = true;
            }
            this.productList.push(...products);
            this.$forceUpdate();
            setTimeout(() => (this.loadingMore = false), 100);
          }
        })
        .finally(() => {
          if (!push) {
            this.loadIndex--;
          }
        });

          
    },
    handleScroll(event) {
      if (!this.$refs.plistRef) {
        return;
      }
      let screenHeight = screen.height;
      let scrollTop = document.documentElement.scrollTop;
      let productListStart = util.findPos(
        this.$refs.plistRef,
        document.documentElement
      ).y;
      let productListEnd = productListStart + this.$refs.plistRef.scrollHeight;
      let diff = productListEnd - scrollTop;
      let bottomOfWindow = diff <= screenHeight * 1.2;
      if (bottomOfWindow) {
        this.listProducts(true);
      }
    },
    showModal: function (productId, product_quantity) {
      
      this.variationIndex = null;
      this.loadIndex++;
      Product.get(productId)
        .then((product) => {
          product.price = product.price / 100;
          product.plotted_price = product.plotted_price / 100;
          product.price_rules = (product.price_rules || []).map((prule) => {
            if (prule.modifier_type === "PRICE") {
              prule.amount = prule.amount / 100;
            }
            return prule;
          });
          product.variations = (product.variations || []).map((pvar) => {
            pvar.price = pvar.price / 100;
            pvar.extends_price = (pvar.parent_fields || []).includes("price");
            pvar.extends_images = (pvar.parent_fields || []).includes("images");
            pvar.extends_box = (pvar.parent_fields || []).includes("box");
            return pvar;
          });
          product.tangible = !!product.box;
          if (!product.box) {
            product.box = {};
          }
          product.recurrent = !!product.recurrence_info;
          if (!product.recurrence_info) {
            product.recurrence_info = {};
          }
          if (!product.categories) {
            product.categories = [];
          } else {
            product.categories = product.categories.map((c) => c.id);
          }
          (product.variations || []).forEach((variation) => {
            if (!variation.categories) {
              variation.categories = [];
            } else {
              variation.categories = variation.categories.map((c) => c.id);
            }
          });
          this.productModal = product;
          this.productModal.product_quantity = product_quantity;
          this.$refs.fileInput.value = "";
          if (this.$refs.relatedFileInput_o_RUT) {
            this.$refs.relatedFileInput_o_RUT.value = "";
          }
        })
        .finally(() => {
          this.loadIndex--;
        });
      this.$modal.show("product-manage-modal");
    },
    manageImages: function (variationIndex = null) {
      this.variationIndex = variationIndex;
      this.$modal.show("image-manage-modal");
    },
    manageRules: function () {
      this.variationIndex = null;
      this.$modal.show("rules-manage-modal");
    },
    manageVariations: function () {
      this.variationIndex = null;
      this.$modal.show("variations-manage-modal");
    },
    manageFiles: function (variationIndex = null) {
      this.variationIndex = variationIndex;
      this.$modal.show("file-manage-modal");
    },
    hideModal: function () {
      this.variationIndex = null;
      this.$refs.fileInput.value = "";
      this.$modal.hide("product-manage-modal");
    },
    hideImageModal: function () {
      this.$modal.hide("image-manage-modal");
    },
    hideFileModal: function () {
      this.$modal.hide("file-manage-modal");
    },
    hideRulesModal: function () {
      this.variationIndex = null;
      this.$modal.hide("rules-manage-modal");
    },
    hideVariationsModal: function () {
      this.variationIndex = null;
      this.$modal.hide("variations-manage-modal");
    },
    addRule: function () {
      this.productModal.price_rules = this.productModal.price_rules || [];
      this.productModal.price_rules.push({
        min_quantity: null,
        max_quantity: null,
        modifier_type: "PERCENTAGE",
        amount: null,
        enabled: true,
        relative: true,
      });
      this.$forceUpdate();
    },
    eraseRule: function (index) {
      this.variationIndex = null;
      this.productModal.price_rules.splice(index, 1);
      this.$forceUpdate();
    },
    saveRules: function () {
      this.loadIndex++;
      axios
        .put(
          `${apiBaseUrl}/api/product/${this.productModal.id}/rules`,
          {
            price_rules: this.productModal.price_rules.map((prule) => {
              prule = JSON.parse(JSON.stringify(prule));
              if (prule.modifier_type === "PRICE") {
                prule.amount = Number(String(prule.amount)) * 100;
              } else {
                prule.amount = Number(String(prule.amount).replace(/\D/g, ""));
              }
              return prule;
            }),
          },
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {
            this.$notify({
              type: "success",
              title: "Regras Salvas",
              text: "Regras Salvas com Sucesso!",
            });
            this.$forceUpdate();
          },
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              duration : 24000,
              title: "Erro ao Salvar Regras",
              text: util.stringifyAxiosError(error),
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    addVariation: function () {
      this.productModal.variations = this.productModal.variations || [];
      this.productModal.variations.push({
        name: "",
        price: "",
        stocked_quantity: "",
        description: "",
        categories: [],
        extends_price: true,
        extends_images: true,
        published: true,
        extends_box: true,
        extends_files: true,
        box: !this.productModal.tangible
          ? null
          : {
              weight: (this.productModal.box || {}).weight,
              length: (this.productModal.box || {}).length,
              width: (this.productModal.box || {}).width,
              height: (this.productModal.box || {}).height,
            },
        images: null,
        related_files: [],
      });
      this.$forceUpdate();
    },
    eraseVariation: function (index) {
      this.productModal.variations.splice(index, 1);
      this.$forceUpdate();
    },
    saveVariations: function () {
      this.loadIndex++;
      axios
        .put(
          `${apiBaseUrl}/api/product/${this.productModal.id}/variations`,
          {
            variations: this.productModal.variations.map((variation) => {
              variation = JSON.parse(JSON.stringify(variation));
              if (variation.price) {
                variation.price = Number(String(variation.price)) * 100;
              }
              if (
                (variation.categories || []).length > 0 &&
                !variation.categories[0]
              ) {
                variation.categories = [];
              } else {
                variation.categories = (variation.categories || []).map(
                  (id) => {
                    return { id };
                  }
                );
              }
              variation.parent_fields = [];
              if (variation.extends_price) {
                variation.parent_fields.push("price");
              }
              if (variation.extends_images) {
                variation.parent_fields.push("images");
              }
              if (variation.extends_box) {
                variation.parent_fields.push("box");
              }
              if (variation.extends_files) {
                variation.parent_fields.push("related_files");
              }
              return variation;
            }),
          },
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {
            this.$notify({
              type: "success",
              title: "Variações Salvas",
              text: "Variações Salvas com Sucesso!",
            });
            this.productModal.variations.forEach((variation, variation_idx) => {
              variation.id = response.data[variation_idx].id;
              this.addImages(this.productModal.id, variation_idx);
            });
            this.$forceUpdate();
          },
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Salvar Variações",
              text: util.stringifyAxiosError(error),
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    leftImage: function (index) {
      if (index == 0) {
        return;
      }
      if (this.variationIndex === null) {
        this.productModal.images = arrayMove(
          this.productModal.images,
          index,
          index - 1
        );
        this.productModal.imageUrl = arrayMove(
          this.productModal.imageUrl,
          index,
          index - 1
        );
      } else {
        this.productModal.variations[this.variationIndex].images = arrayMove(
          this.productModal.variations[this.variationIndex].images,
          index,
          index - 1
        );
        this.productModal.variations[this.variationIndex].imageUrl = arrayMove(
          this.productModal.variations[this.variationIndex].imageUrl,
          index,
          index - 1
        );
      }
      this.$refs.imageManageModal.$forceUpdate();
      this.commitImageMove(null, null, this.variationIndex);
    },
    rightImage: function (index) {
      if (
        index ==
        (this.variationIndex !== null
          ? this.productModal.variations[this.variationIndex].images
          : this.productModal.images
        ).length -
          1
      ) {
        return;
      }
      if (this.variationIndex === null) {
        this.productModal.images = arrayMove(
          this.productModal.images,
          index,
          index + 1
        );
        this.productModal.imageUrl = arrayMove(
          this.productModal.imageUrl,
          index,
          index + 1
        );
      } else {
        this.productModal.variations[this.variationIndex].images = arrayMove(
          this.productModal.variations[this.variationIndex].images,
          index,
          index + 1
        );
        this.productModal.variations[this.variationIndex].imageUrl = arrayMove(
          this.productModal.variations[this.variationIndex].imageUrl,
          index,
          index + 1
        );
      }
      this.$refs.imageManageModal.$forceUpdate();
      this.commitImageMove(null, null, this.variationIndex);
    },
    leftFile: function (index) {
      if (index == 0) {
        return;
      }
      if (this.variationIndex === null) {
        this.productModal.related_files = arrayMove(
          this.productModal.related_files,
          index,
          index - 1
        );
      } else {
        this.productModal.variations[
          this.variationIndex
        ].related_files = arrayMove(
          this.productModal.variations[this.variationIndex].related_files,
          index,
          index - 1
        );
      }
      this.$refs.fileManageModal.$forceUpdate();
      this.commitFileMove(null, null, this.variationIndex);
    },
    rightFile: function (index) {
      if (
        index ==
        (this.variationIndex !== null
          ? this.productModal.variations[this.variationIndex].related_files
          : this.productModal.related_files
        ).length -
          1
      ) {
        return;
      }
      if (this.variationIndex === null) {
        this.productModal.related_files = arrayMove(
          this.productModal.related_files,
          index,
          index + 1
        );
      } else {
        this.productModal.variations[
          this.variationIndex
        ].related_files = arrayMove(
          this.productModal.variations[this.variationIndex].related_files,
          index,
          index + 1
        );
      }
      this.$refs.fileManageModal.$forceUpdate();
      this.commitFileMove(null, null, this.variationIndex);
    },
    commitImageMove: function (
      id = null,
      images = null,
      variation_index = null
    ) {
      axios
        .patch(
          `${apiBaseUrl}/api/product/${id ? id : this.productModal.id}/images`,
          util.cleanObject({
            images: images
              ? images
              : variation_index !== null
              ? this.productModal.variations[variation_index].images
              : this.productModal.images,
            variation_id:
              variation_index !== null
                ? this.productModal.variations[variation_index].id
                : null,
          }),
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {},
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Mover Imagem",
              text: util.stringifyAxiosError(error),
            });
          }
        );
    },
    commitFileMove: function (
      id = null,
      related_files = null,
      variation_index = null
    ) {
      axios
        .patch(
          `${apiBaseUrl}/api/product/${
            id ? id : this.productModal.id
          }/related_files`,
          util.cleanObject({
            related_files: related_files
              ? related_files
              : variation_index !== null
              ? this.productModal.variations[variation_index].related_files
              : this.productModal.related_files,
            variation_id:
              variation_index !== null
                ? this.productModal.variations[variation_index].id
                : null,
          }),
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {},
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Mover Arquivo",
              text: util.stringifyAxiosError(error),
            });
          }
        );
    },
    eraseImage: function (imgId) {
      let pid = this.productModal.id;
      let vari =
        this.variationIndex === null
          ? null
          : this.productModal.variations[this.variationIndex];
      axios
        .delete(
          `${apiBaseUrl}/api/product/${pid}/image/${imgId}` +
            `?apartment=${process.env.VUE_APP_TENANT_ID}` +
            (vari ? `&variation_id=${vari.id}` : ""),
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {
            this.$notify({
              type: "success",
              title: "Imagem Excluída",
              text: "Imagem Excluída com Sucesso!",
            });
            if (vari) {
              this.productModal.variations[
                this.variationIndex
              ].images = this.productModal.variations[
                this.variationIndex
              ].images.filter((i) => i != imgId);
              this.productModal.variations[
                this.variationIndex
              ].imageUrl = this.productModal.variations[
                this.variationIndex
              ].imageUrl.filter((iu) => !String(iu).includes(imgId));
            } else {
              this.productModal.images = this.productModal.images.filter(
                (i) => i != imgId
              );
              this.productModal.imageUrl = this.productModal.imageUrl.filter(
                (iu) => !String(iu).includes(imgId)
              );
            }
            this.productList = this.productList.map((p) =>
              p.id == this.productModal.id ? this.productModal : p
            );
            this.$forceUpdate();
          },
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Excluir Imagem",
              text: util.stringifyAxiosError(error),
            });
          }
        );
    },
    eraseFile: function (fileObj) {
      let pid = this.productModal.id;
      let vari =
        this.variationIndex === null
          ? null
          : this.productModal.variations[this.variationIndex];
      const fileObjId = fileObj.id;
      axios
        .delete(
          `${apiBaseUrl}/api/product/${pid}/related_file/${fileObjId}` +
            `?apartment=${process.env.VUE_APP_TENANT_ID}` +
            (vari ? `&variation_id=${vari.id}` : ""),
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {
            this.$notify({
              type: "success",
              title: "Arquivo Excluído",
              text: "Arquivo Excluído com Sucesso!",
            });
            if (vari) {
              this.productModal.variations[
                this.variationIndex
              ].related_files = this.productModal.variations[
                this.variationIndex
              ].related_files.filter((i) => i != fileObjId);
            } else {
              this.productModal.related_files = this.productModal.related_files.filter(
                (i) => i != fileObjId
              );
            }
            this.productList = this.productList.map((p) =>
              p.id == this.productModal.id ? this.productModal : p
            );
            this.$forceUpdate();
          },
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Excluir Arquivo",
              text: util.stringifyAxiosError(error),
            });
          }
        );
    },
    addImages: function (product_id = null, variation_index = null) {
      let qtyImages =
        variation_index !== null
          ? (this.variationFiles[variation_index] || []).length
            ? (this.variationFiles[variation_index] || []).length
            : 0
          : this.binaryDataImg.length
          ? this.binaryDataImg.length
          : 0;

      if (qtyImages <= 0) {
        return;
      }

      this.$notify({
        type: "info",
        title: "Adicionando Imagens",
        text: "As imagens estão sendo adicionadas...",
      });

      let finishedImages = [];
      let preExistingImages = (
        (variation_index !== null
          ? this.productModal.variations[variation_index].images
          : this.productModal.images) || []
      ).slice();

      for (let i = 0; i < qtyImages; i++) {
        ((index) => {
          this.loadIndex++;
          axios
            .put(
              `${apiBaseUrl}/api/product/${product_id}/image` +
                (variation_index !== null
                  ? `?variation_id=${this.productModal.variations[variation_index].id}`
                  : ""),
              variation_index !== null
                ? this.variationFiles[variation_index][index]
                : this.binaryDataImg[index],
              {
                headers: HeaderBuilder.build(),
              }
            )
            .then(
              (response) => {
                const actedUpon = variation_index
                  ? this.productModal.variations[variation_index]
                  : this.productModal;
                if (actedUpon && actedUpon.images && response.data) {
                  if (!actedUpon.images) {
                    actedUpon.images = [];
                  }
                  actedUpon.images.push(response.data.id);
                  if (!actedUpon.imageUrl) {
                    actedUpon.imageUrl = [];
                  }
                  actedUpon.imageUrl.push(
                    Product.buildImageUrl(
                      this.productModal.id,
                      response.data.id,
                      variation_index ? actedUpon.id : null
                    )
                  );
                  this.$forceUpdate();
                }
                finishedImages[index] = response.data.id;
                if (
                  // Ensure at least length matches
                  finishedImages.length === qtyImages &&
                  // Ensure no null or undefined elements
                  !finishedImages.some((v) => typeof v !== "string") &&
                  // Ensure not sparse
                  Object.values(finishedImages).length === finishedImages.length
                ) {
                  if (variation_index === null) {
                    this.hideModal();
                  } else {
                    this.hideVariationsModal();
                  }
                  this.$notify({
                    type: "success",
                    title: "Imagens adicionadas com sucesso!",
                    text:
                      "As imagens foram adicionadas e ordenadas com sucesso!",
                  });
                  this.commitImageMove(
                    product_id,
                    preExistingImages.concat(finishedImages),
                    variation_index
                  );
                }
              },
              (error) => {
                this.$notify({
                  type: "error",
                  title: "Erro ao adicionar imagem no produto",
                  text: util.stringifyAxiosError(error),
                });
              }
            )
            .finally(() => {
              this.loadIndex--;
            });
        })(i);
        if (this.$refs["fileInput_var_" + variation_index]) {
          this.$refs["fileInput_var_" + variation_index].value = "";
        }
      }
    },
    addFiles: function (product_id = null, variation_index = null) {
      let qtyFiles =
        variation_index !== null
          ? (this.variationRelatedFiles[variation_index] || []).length
            ? (this.variationRelatedFiles[variation_index] || []).length
            : 0
          : this.binaryDataFile.length
          ? this.binaryDataFile.length
          : 0;

      if (qtyFiles <= 0) {
        return;
      }

      this.$notify({
        type: "info",
        title: "Adicionando Arquivos",
        text: "Os arquivos estão sendo adicionados...",
      });

      let finishedFiles = [];

      for (let i = 0; i < qtyFiles; i++) {
        ((index) => {
          this.loadIndex++;
          axios
            .put(
              `${apiBaseUrl}/api/product/${product_id}/related_file` +
                (variation_index !== null
                  ? `?variation_id=${this.productModal.variations[variation_index].id}`
                  : ""),
              variation_index !== null
                ? this.variationRelatedFiles[variation_index][index]
                : this.binaryDataFile[index],
              {
                headers: {
                  ...HeaderBuilder.build(),
                  ...{
                    "X-Zankh-Downloadable": String(
                      this.relatedFileCad.downloadable
                    ),
                    "X-Zankh-Published": String(this.relatedFileCad.published),
                    "X-Zankh-Buyable": String(this.relatedFileCad.buyable),
                    "X-Zankh-Name": this.relatedFileCad.name,
                    "X-Zankh-Description": this.relatedFileCad.description,
                  },
                },
              }
            )
            .then(
              (response) => {
                const actedUpon = variation_index
                  ? this.productModal.variations[variation_index]
                  : this.product;
                if (actedUpon && actedUpon.related_files && response.data) {
                  actedUpon.related_files.push(response.data);
                  this.$forceUpdate();
                }
                finishedFiles[index] = response.data.id;
                if (
                  // Ensure at least length matches
                  finishedFiles.length === qtyFiles &&
                  // Ensure no null or undefined elements
                  !finishedFiles.some((v) => !v || typeof v.id !== "string") &&
                  // Ensure not sparse
                  Object.values(finishedFiles).length === finishedFiles.length
                ) {
                  if (variation_index === null) {
                    this.hideModal();
                  } else {
                    this.hideVariationsModal();
                  }
                  this.$notify({
                    type: "success",
                    title: "Arquivos adicionados com sucesso!",
                    text:
                      "Os arquivos foram adicionados e ordenados com sucesso!",
                  });
                  this.commitFileMove(
                    product_id,
                    (variation_index !== null
                      ? this.productModal.variations[variation_index]
                          .related_file
                      : this.productModal.related_file
                    ).concat(finishedFiles),
                    variation_index
                  );
                }
              },
              (error) => {
                this.$notify({
                  type: "error",
                  title: "Erro ao adicionar arquivo ao produto!",
                  text: util.stringifyAxiosError(error),
                });
              }
            )
            .finally(() => {
              this.loadIndex--;
            });
        })(i);
        if (this.$refs["relatedFileInput_o_" + variation_index]) {
          this.$refs["relatedFileInput_o_" + variation_index].value = "";
        }
      }
    },
    updateProduct: function () {
      var prod = JSON.parse(JSON.stringify(this.productModal));
      prod.price = prod.price * 100;
      prod.plotted_price = prod.plotted_price * 100;

      if (!prod.tangible) {
        prod.box = null;
      }

      if (prod.categories.length > 0 && !prod.categories[0]) {
        prod.categories = [];
      } else {
        prod.categories = prod.categories.map((id) => {
          return { id };
        });
      }

      this.loadIndex++;
      Product.edit(prod)
        .then(
          (serviceReturn) => {
            this.$notify({
              type: "success",
              title: "Produto Atualizado",
              text: "Produto Atualizado com Sucesso!",
            });

            this.productList = this.productList.map((p) =>
              p.id == prod.id ? prod : p
            );

            this.addImages(prod.id, null);
          },
          (error) => {
            this.hideModal();
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Editar Produto",
              text: util.stringifyAxiosError(error),
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    deleteProduct: function (pid) {
      this.$swal({
        title: "Tem certeza que deseja APAGAR o produto?",
        text: "Não é possível reverter essa ação!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Sim, pode apagar!",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
            dangerMode: true,
          },
        },
      }).then((result) => {
        if (!result) {
          return;
        }
        axios
          .delete(`${apiBaseUrl}/api/product/${pid}`, {
            headers: HeaderBuilder.build(),
          })
          .then(
            (response) => {
              this.$notify({
                type: "success",
                title: "Produto Excluido",
                text: "Produto Excluido com Sucesso!",
              });
              this.productList = this.productList.filter((p) => p.id != pid);
            },
            (error) => {
              this.$notify({
                type: "error",
                duration : 24000,
                title: "Erro ao Excluir Produto",
                text: util.stringifyAxiosError(error),
              });
            }
          );
      });
    },
    processFile(event) {
      this.binaryDataImg = event.target.files;
    },
    processVariationFile(event, index) {
      this.variationFiles[index] = event.target.files;
    },
    processRelatedFile(event) {
      this.binaryDataFile = event.target.files;
    },
    processVariationRelatedFile(event, index) {
      this.variationRelatedFiles[index] = event.target.files;
    },
  },
  components: {
    money: Money,
    "the-mask": TheMask,
    Loading,
  },
  computed: {
    isLoading: function () {
      return this.loadIndex > 0;
    },
  },
};
</script>